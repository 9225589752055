import React, { useRef, useState, lazy, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SttButton, SttTranslateHook, SttGrid, SttAutocomplete, SttInput, SttMaskedInput, SttModal, SttNotification } from '@stt-componentes/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../../request';
import { Formik, Field, FastField } from 'formik';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '../../../../reducers/actions/alerta';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../reducers/actions/carregando';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
const SttInstituicao = lazy(() => import('@stt-componentes/instituicao'));

const useStyles = makeStyles((theme) => ({
	form: {
		marginTop: '10px',
		marginBottom: '30px'
	},
	buttonWrapper: {
		marginTop: theme.spacing(1)
	},
	notificacao: {
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1)
	}
}));

const initialValues = {
	idFuncionario: null,
	cpf: '',
	instituicao: null,
	outraInstituicao: null,
	equipeSaude: null,
	email: '',
	sexo: null,
	nome: '',
	telefone: ''
};

const ListaPresencaParticipanteForm = ({
	user,
	participantes,
	idWebconferencia,
	callbackCancelar,
	mostrarCarregando,
	callbackRegistroParticipante,
	setParametrosAlerta,
	setOpen
}) => {
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const schema = validationSchema(strings);
	const formRef = useRef();
	const classes = useStyles();

	const [modalOpen, setModalOpen] = useState(true);
	const [equipesSaude, setEquipesSaude] = useState(false);
	const [sexos, setSexos] = useState([]);
	const [instituicoesConvidado, setInstituicoesConvidado] = useState([]);
	const [equipesSaudeConvidado, setEquipesSaudeConvidado] = useState(false);
	const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
	const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

	const pesquisarEquipes = (idFuncionario) => {
		const params = {
			idFuncionario
		};
		mostrarCarregando(true);
		axios
			.get(`${global.gConfig.url_base_tele_educacao}/equipe-saude`, { params, headers: getHeaders() })
			.then((response) => {
				const { data } = response.data;
				if (data) {
					if (parseInt(data.totalRegistros) > 1) {
						setEquipesSaude(data.itens);
						return;
					}
					if (parseInt(data.totalRegistros) === 1) {
						setEquipesSaude(data.itens);
						formRef.current.setFieldValue('equipeSaude', data.itens[0]);
						return;
					}
				}
				setEquipesSaude([]);
			})
			.catch((err) => {
				console.log(err);
				setEquipesSaude([]);
			})
			.finally(() => {
				mostrarCarregando(false);
			});
	};

	const pesquisarParticipanteWebconferencia = (cpf) => {
		const params = {
			cpf: cpf.replace(/\D+/g, ''),
			id: idWebconferencia
		};
		mostrarCarregando(true);
		axios
			.get(`${global.gConfig.url_base_tele_educacao}/webconferencia/participante`, { params, headers: getHeaders() })
			.then((response) => {
				const { data, message } = response.data;
				const parametrosAlerta = {
					titulo: strings.atencao,
					mensagem: message,
					tipo: 'alert',
					open: true,
					opcoes: [
						{
							title: strings.ok,
							onClick: () => {
								setOpen(false);
							}
						}
					]
				};
				setParametrosAlerta(parametrosAlerta);
				if (data.registrado) {
					return;
				}
				if (data.idFuncionario) {
					pesquisarEquipes(data.idFuncionario);
					formRef.current.setStatus('SELECAO_EQUIPE');
					formRef.current.setFieldValue('idFuncionario', data.idFuncionario);
					return;
				}
				formRef.current.setStatus('CADASTRO_CONVIDADO');
				pesquisarSexos();
				pesquisarInstituicoesConvidado(cpf);
			})
			.catch((err) => {
				const { response } = err;
				let mensagem = strings.erro;
				if (response) {
					const { data } = response;

					mensagem = data.message;
				}

				const parametrosAlerta = {
					titulo: strings.erro,
					mensagem: mensagem,
					tipo: 'error',
					open: true,
					opcoes: [
						{
							title: strings.ok,
							onClick: () => {
								setOpen(false);
							}
						}
					]
				};
				setParametrosAlerta(parametrosAlerta);
			})
			.finally(() => {
				mostrarCarregando(false);
			});
	};

	const pesquisarSexos = () => {
		if (sexos.length === 0) {
			mostrarCarregando(true);
			axios
				.get(`${global.gConfig.url_base_utilitarios}/sexo`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						const { itens } = response.data.data;
						setSexos(itens);
					}
				})
				.catch((err) => console.log(err))
				.finally(() => {
					mostrarCarregando(false);
				});
		}
	};

	const enviarParticipante = (dados, setSubmitting) => {
		let dadosFormatados = JSON.parse(JSON.stringify(dados));
		dadosFormatados.alteracao = true;
		dadosFormatados.idWebconferencia = idWebconferencia;
		dadosFormatados.status = formRef.current.status;

		if (dadosFormatados.instituicao?.id && dadosFormatados.instituicao.id === -1) {
			dadosFormatados.instituicao = dadosFormatados.outraInstituicao;
		}

		setSubmitting(true);
		mostrarCarregando(true, strings.salvandoMensagemEspera);
		axios
			.post(`${global.gConfig.url_base_tele_educacao}/webconferencia/participante`, dadosFormatados, { headers: getHeaders() })
			.then((response) => {
				const parametrosAlerta = {
					titulo: strings.sucesso,
					mensagem: strings.sucessoRegistroParticipante,
					tipo: 'success',
					open: true,
					opcoes: [
						{
							title: strings.ok,
							onClick: () => {
								setOpen(false);
								setModalOpen(false);
								callbackRegistroParticipante();
							}
						}
					]
				};
				setParametrosAlerta(parametrosAlerta);
			})
			.catch((err) => {
				const { response } = err;
				let mensagem = strings.erro;
				if (response) {
					const { data } = response;

					mensagem = data.message;
					if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
						let arrMensagem = [];
						data.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						if (arrMensagem.length > 0) {
							mensagem = arrMensagem.join('\n');
						}
					}
				}

				const parametrosAlerta = {
					titulo: strings.erro,
					mensagem: mensagem,
					tipo: 'error',
					open: true,
					opcoes: [
						{
							title: strings.ok,
							onClick: () => {
								setOpen(false);
							}
						}
					]
				};
				setParametrosAlerta(parametrosAlerta);
			})
			.finally(() => {
				mostrarCarregando(false);
				if (setSubmitting) {
					setSubmitting(false);
				}
			});
	};

	const pesquisarInstituicoesConvidado = (cpf) => {
		// 1º pesquisar por instituições do usuário no CADSUS e CNES
		var cpfFormatado = ('00000000000' + cpf.replace(/[\. ,:-]+/g, '')).slice(-11);
		mostrarCarregando(true, strings.pesquisandoDadosCadsus);
		axios
			.get(`${ADM_API_BASE_URL}/funcionario-cadsus?_id=${cpfFormatado}`, { headers: getHeaders() })
			.then((response) => {
				if (response.data?.data) {
					const { data } = response;
					let instituicoesFormatadas = [];
					let dados = data.data[0];

					if (formRef?.current?.setFieldValue) {
						///Nome
						if (dados.nome) {
							formRef.current.setFieldValue('nome', dados.nome);
						}

						//Gênero
						if (dados.sigla_sexo) {
							axios
								.get(`${UTILITARIOS_API_BASE_URL}/sexo`, { headers: getHeaders() })
								.then((response) => {
									const dataGenero = response.data;

									if (dataGenero) {
										const { itens } = dataGenero.data;
										itens.forEach((genero) => {
											if (genero.sigla === dados.sigla_sexo) {
												formRef.current.setFieldValue('sexo', genero);
											}
										});
									}
								})
								.catch((err) => console.log(err));
						}
					}

					if (dados.instituicoes && dados.instituicoes.length) {
						dados.instituicoes.forEach((instituicao) => {
							let instituicaoFormatada = {
								id: instituicao.id_instituicao,
								nome: instituicao.nome_instituicao,
								cnes: instituicao.cnes_instituicao,
								municipio: instituicao.nome_cidade_instituicao,
								estado: instituicao.sigla_estado_instituicao
							};
							instituicoesFormatadas.push(instituicaoFormatada);
						});
					}
					// 2º concatenar essas instituições a do usuário participante
					// 3º criar opçao "Outra instituição"
					const participante = participantes.find(
						(participante) => participante.id_funcionario === user.idFuncionario && !participante.convidado
					);
					if (participante) {
						instituicoesFormatadas = [
							{ id: participante.id_instituicao, nome: participante.nome_instituicao },
							...instituicoesFormatadas,
							{ id: -1, nome: 'Outras' }
						];
					}
					// Formata a equipe de saúde do participante para ser utilizada posteriormente
					let equipeSaudeParticipante = null;
					if (participante.id_equipe) {
						equipeSaudeParticipante = {
							id: participante.id_equipe,
							nome_equipe: participante.nome_equipe,
							nome_cidade: participante.nome_cidade,
							nome_instituicao: participante.nome_instituicao
						};
					}

					// Tratativa de instituições potencialmente duplicadas
					const idsUnicos = new Set();
					instituicoesFormatadas = instituicoesFormatadas.filter((item) => !idsUnicos.has(item.id) && idsUnicos.add(item.id));

					setInstituicoesConvidado(instituicoesFormatadas);
					if (formRef?.current?.setFieldValue) {
						// 4º por padrão, deixar setado instituição e equipe do usuário que está digitante o convidado
						formRef.current.setFieldValue('instituicao', instituicoesFormatadas[0]);
						// 5º fazer o mesmo procedimento com as equipes de saúde
						pesquisarEquipesConvidado(instituicoesFormatadas[0], equipeSaudeParticipante);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				mostrarCarregando(false);
			});
	};

	const pesquisarEquipesConvidado = (instituicao, equipeSaudeParticipante) => {
		const params = {
			idInstituicao: instituicao.id,
			ativo: true
		};

		axios
			.get(`${global.gConfig.url_base_tele_educacao}/pesquisar-equipe-saude`, { params, headers: getHeaders() })
			.then((response) => {
				const { data } = response.data;
				setEquipesSaudeConvidado(data || []);

				if (data.length === 1) {
					formRef.current.setFieldValue('equipeSaude', data[0]);
				} else if (formRef?.current?.setFieldValue && equipeSaudeParticipante) {
					formRef.current.setFieldValue('equipeSaude', equipeSaudeParticipante);
				} else {
					formRef.current.setFieldValue('equipeSaude', null);
				}
			})
			.catch((err) => {
				console.log(err);
				setEquipesSaudeConvidado([]);
			});
	};

	return (
		<>
			<SttModal
				title={strings.inclusaoParticipante}
				open={modalOpen}
				maxWidth="md"
				fullWidth={true}
				outModalClose={callbackCancelar}
				iconClose={callbackCancelar}
				children={
					<Formik
						innerRef={formRef}
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={(dados, { setSubmitting }) => {
							setSubmitting(true);
							enviarParticipante(dados, setSubmitting);
						}}
					>
						{({ values, status, setFieldValue, handleSubmit, resetForm, errors }) => (
							<>
								<SttNotification
									severity="error"
									className={classes.notificacao}
								>
									{strings.alertaConvidadoCadastro}
								</SttNotification>
								<SttNotification
									severity="info"
									className={classes.notificacao}
								>
									{strings.instrucaoListaPresenca}
								</SttNotification>
								<form
									onSubmit={handleSubmit}
									noValidate
									onKeyDown={(e) => {
										if (e.key === 'Enter' && !status) {
											e.preventDefault();
											if (!errors['cpf']) {
												pesquisarParticipanteWebconferencia(values.cpf);
											}
										}
									}}
								>
									<SttGrid
										container
										spacing={1}
									>
										<SttGrid
											item
											xs={12}
											sm={8}
											md={4}
										>
											<Field name={`cpf`}>
												{({ field, meta }) => (
													<SttMaskedInput
														{...field}
														mask="cpf"
														label={strings.cpfParticipante}
														disabled={status === 'SELECAO_EQUIPE' || status === 'CADASTRO_CONVIDADO'}
														required
														error={meta.touched && meta.error ? true : false}
														helperText={meta.touched && meta.error ? meta.error : undefined}
													/>
												)}
											</Field>
										</SttGrid>
										<SttGrid
											item
											xs={12}
											sm={4}
											md={2}
										>
											<div className={classes.buttonWrapper}>
												{status === 'SELECAO_EQUIPE' || status === 'CADASTRO_CONVIDADO' ? (
													<SttButton
														variant="contained"
														type="button"
														color="primary"
														onClick={() => {
															resetForm();
															setEquipesSaude([]);
														}}
													>
														{strings.cancelar}
													</SttButton>
												) : (
													<SttButton
														variant="contained"
														type="button"
														color="primary"
														onClick={() => {
															// validateField('cpf');
															if (!errors['cpf']) {
																pesquisarParticipanteWebconferencia(values.cpf);
															}
														}}
													>
														{strings.pesquisar}
													</SttButton>
												)}
											</div>
										</SttGrid>
										{status === 'SELECAO_EQUIPE' && (
											<SttGrid
												item
												xs={12}
												sm={12}
												md={6}
											>
												<Field name={'equipeSaude'}>
													{({ field: { name, value, onBlur }, meta }) => (
														<SttAutocomplete
															inputprops={{
																name: name,
																required: true,
																label: strings.equipeSaudeFamilia,
																error: meta.touched && meta.error ? meta.error : undefined
															}}
															getOptionLabel={(option) =>
																option?.nome_cidade
																	? `${option.nome_cidade} - ${option.nome_equipe} - Instituição: ${option.nome_instituicao}`
																	: ''
															}
															getOptionSelected={(option, val) => option?.id === val?.id}
															options={equipesSaude}
															value={value}
															onBlur={onBlur}
															onChange={(e, item) => {
																const equipe = item || null;
																setFieldValue('equipeSaude', equipe);
															}}
														/>
													)}
												</Field>
											</SttGrid>
										)}
									</SttGrid>
									{status === 'SELECAO_EQUIPE' && (
										<SttNotification
											severity="warning"
											className={classes.notificacao}
										>
											{strings.avisoSemEquipe}
										</SttNotification>
									)}
									{status === 'CADASTRO_CONVIDADO' && (
										<>
											<SttNotification
												severity="info"
												className={classes.notificacao}
											>
												{strings.avisoNovoCadastro}
											</SttNotification>
											<SttGrid
												container
												spacing={1}
											>
												<SttGrid
													item
													xs={12}
													sm={8}
												>
													<FastField name="nome">
														{({ meta, field }) => (
															<SttInput
																inputProps={{
																	maxLength: 100
																}}
																{...field}
																required
																label={strings.nomeCompleto}
																error={meta.touched && meta.error ? true : false}
																helperText={meta.touched && meta.error ? meta.error : undefined}
															/>
														)}
													</FastField>
												</SttGrid>
												<SttGrid
													item
													xs={12}
													sm={4}
												>
													<Field name={'sexo'}>
														{({ field: { name, value, onBlur }, form: { setFieldValue }, meta }) => (
															<SttAutocomplete
																inputprops={{
																	name: name,
																	required: true,
																	label: strings.genero,
																	error: meta.touched && meta.error ? meta.error : undefined
																}}
																className={classes.autocomplete}
																getOptionLabel={(option) => option?.descricao || ''}
																getOptionSelected={(option, val) => option?.id === val?.id}
																options={sexos}
																required
																value={value}
																onBlur={onBlur}
																onChange={(e, item) => {
																	const sexo = item || null;
																	setFieldValue('sexo', sexo);
																}}
															/>
														)}
													</Field>
												</SttGrid>
												<SttGrid
													item
													xs={12}
													sm={8}
												>
													<FastField name={'email'}>
														{({ field, meta }) => (
															<SttInput
																inputProps={{
																	maxLength: 100
																}}
																{...field}
																required={true}
																label={strings.email}
																error={meta.touched && meta.error ? true : false}
																helperText={meta.touched && meta.error ? meta.error : undefined}
															/>
														)}
													</FastField>
												</SttGrid>
												<SttGrid
													item
													xs={12}
													md={4}
												>
													<Field name={'telefone'}>
														{({ field, meta }) => (
															<SttMaskedInput
																{...field}
																mask={'telefoneCelular'}
																label={strings.telefoneCelular}
																required
																error={meta.touched && meta.error ? true : false}
																helperText={meta.touched && meta.error ? meta.error : undefined}
															/>
														)}
													</Field>
												</SttGrid>
												<SttGrid
													item
													xs={12}
													sm={12}
													md={6}
												>
													<Field name={'instituicao'}>
														{({ field: { name, value, onBlur }, meta }) => (
															<SttAutocomplete
																inputprops={{
																	name: name,
																	label: strings.instituicao,
																	error: meta.touched && meta.error ? meta.error : undefined
																}}
																getOptionLabel={(option) => option.nome}
																getOptionSelected={(option, val) => option?.id === val?.id}
																options={instituicoesConvidado}
																value={value}
																onBlur={onBlur}
																onChange={(e, item) => {
																	const instituicao = item || null;
																	setFieldValue('instituicao', instituicao);
																	setFieldValue('equipeSaude', null);
																	setEquipesSaudeConvidado([]);
																	if (instituicao) {
																		pesquisarEquipesConvidado(instituicao);
																	}
																}}
															/>
														)}
													</Field>
												</SttGrid>
												{values['instituicao']?.id === -1 && (
													<SttGrid
														item
														xs={12}
														sm={12}
														md={6}
													>
														<Field name={'outraInstituicao'}>
															{({ form }) => (
																<SttInstituicao
																	fieldName={'outraInstituicao'}
																	headers={getHeaders()}
																	strings={strings}
																	obrigatorio={false}
																	onChange={pesquisarEquipesConvidado}
																	formExterno={{
																		outraInstituicao: initialValues.outraInstituicao,
																		...form
																	}}
																/>
															)}
														</Field>
													</SttGrid>
												)}
												<SttGrid
													item
													xs={12}
													sm={12}
													md={6}
												>
													<Field name={'equipeSaude'}>
														{({ field: { name, value, onBlur }, meta }) => (
															<SttAutocomplete
																inputprops={{
																	name: name,
																	label: strings.equipeSaudeFamilia,
																	error: meta.touched && meta.error ? meta.error : undefined
																}}
																disabled={!equipesSaudeConvidado.length}
																getOptionLabel={(option) =>
																	option?.nome_cidade
																		? `${option.nome_cidade} - ${option.nome_equipe} - Instituição: ${option.nome_instituicao}`
																		: ''
																}
																getOptionSelected={(option, val) => option?.id === val?.id}
																options={equipesSaudeConvidado}
																value={value}
																onBlur={onBlur}
																onChange={(e, item) => {
																	const equipe = item || null;
																	setFieldValue('equipeSaude', equipe);
																}}
															/>
														)}
													</Field>
												</SttGrid>
											</SttGrid>
										</>
									)}
								</form>
							</>
						)}
					</Formik>
				}
				footer={
					<>
						<SttButton
							variant="contained"
							disabled={
								formRef.current &&
								(formRef.current.isSubmitting ||
									(formRef.current.status !== 'SELECAO_EQUIPE' && formRef.current.status !== 'CADASTRO_CONVIDADO'))
							}
							color="primary"
							onClick={() => {
								formRef.current.submitForm();
							}}
						>
							{strings.confirmar}
						</SttButton>
						<SttButton
							variant="outlined"
							color="primary"
							onClick={() => {
								setModalOpen(false);
								callbackCancelar();
							}}
						>
							{strings.fechar}
						</SttButton>
					</>
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
		setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
		setOpen: (open) => dispatch(setOpenAction(open))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPresencaParticipanteForm);
