import React, { useEffect, useRef, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, FastField } from 'formik';
import { SttGrid, SttContainer, SttTranslateHook, SttAutocomplete, SttInput } from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../../../../request';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../../reducers/actions/carregando';
import { DESCRICAO_MATERIAL, NOME, TEMA, URL } from './fieldnames';
import schema from './validationSchema';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '../../../../../reducers/actions/alerta';
import httpStatus from 'http-status-codes';

const useStyles = makeStyles((theme) => ({
	errorText: {
		marginTop: '-2px'
	},
	form: {
		marginTop: '10px',
		marginBottom: '30px'
	},
	grid: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap'
	},
	heading: {
		marginTop: theme.spacing(1)
	},
	buttons: {
		display: 'flex',
		marginTop: theme.spacing(1),
		justifyContent: 'flex-start'
	},
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	}
}));

const initialValues = {
	nome: '',
	tema: null,
	descricaoMaterial: '',
	url: ''
};

const FormOpcoes = ({
	setBuscar,
	setModalMaterial,
	setRecarregarWebconferencia,
	idWebconferencia,
	mostrarCarregando,
	setRef,
	materialEdicao,
	setParametrosAlerta,
	setOpen
}) => {
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();
	const validationSchema = schema(strings);
	const ref = useRef();

	const [temas, setTemas] = useState([]);

	const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;

	useEffect(() => {
		if (materialEdicao && ref?.current) {
			ref.current.resetForm({
				values: {
					nome: materialEdicao.nome,
					tema: {
						id: materialEdicao.id_tema,
						descricao: materialEdicao.tema
					},
					descricaoMaterial: materialEdicao.conteudo_material,
					url: materialEdicao.url,
					id: materialEdicao.id
				}
			});
		}
	}, [ref, materialEdicao]);

	useEffect(() => {
		if (ref?.current) {
			setRef(ref);
		}
	}, [ref]);

	useEffect(() => {
		axios
			.get(`${TELE_EDUCACAO_API_BASE_URL}/tema/ativo`, { headers: getHeaders() })
			.then((response) => {
				if (response.data) {
					const itens = response.data.data;
					setTemas(itens);
				}
			})
			.catch((err) => console.log(err));
	}, []);

	const submitForm = (data) => {
		mostrarCarregando(true);
		axios
			.post(`${TELE_EDUCACAO_API_BASE_URL}/material`, data, { headers: getHeaders() })
			.then((response) => {
				if (response?.data?.message) {
					setParametrosAlerta({
						titulo: strings.sucesso,
						mensagem: response.data.message,
						tipo: 'success',
						open: true,
						opcoes: [
							{
								title: strings.ok,
								onClick: () => {
									setOpen(false);
									setModalMaterial(false);
									setRecarregarWebconferencia(true);
									setBuscar(true);
								}
							}
						]
					});
				}
			})
			.catch((err) => {
				const { response } = err;
				let mensagemAlertaErro = strings.mensagemErroGeral;

				if (response && response.status === httpStatus.BAD_REQUEST) {
					const { data } = response;
					let arrMensagem = [];
					data.errors.forEach((error) => {
						arrMensagem.push(`- ${error.message}`);
					});
					mensagemAlertaErro = arrMensagem.join('\n');
				}

				const parametrosAlertaErro = {
					titulo: strings.erro,
					mensagem: mensagemAlertaErro,
					tipo: 'error',
					open: true,
					opcoes: [
						{
							title: strings.ok,
							onClick: () => {
								setOpen(false);
							}
						}
					]
				};

				setParametrosAlerta(parametrosAlertaErro);
			})
			.finally(() => {
				mostrarCarregando(false);
			});
	};

	return (
		<Formik
			innerRef={ref}
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(data) => {
				let mensagem = strings.confirmacaoMaterial;
				if (data.id) {
					mensagem = strings.confirmacaoAlteracaoMaterial;
				}
				data.idWebconferencia = idWebconferencia;
				setParametrosAlerta({
					titulo: strings.atencao,
					mensagem: mensagem,
					open: true,
					tipo: 'alert',
					opcoes: [
						{
							title: strings.sim,
							onClick: () => {
								submitForm(data);
							}
						},
						{
							title: strings.nao,
							onClick: () => {
								setOpen(false);
							}
						}
					],
					onClose: () => {
						setOpen(false);
					}
				});
			}}
			validateOnChange={false}
		>
			{({ values }) => {
				return (
					<SttContainer>
						<form className={classes.form}>
							<SttGrid
								container
								className={classes.grid}
								spacing={1}
							>
								<SttGrid
									item
									xs={12}
									sm={6}
								>
									<FastField name={NOME}>
										{({ field, meta }) => (
											<SttInput
												inputProps={{
													maxLength: 100,
													style: { textTransform: 'uppercase' }
												}}
												{...field}
												label={strings.nome}
												required={true}
												error={meta.touched && meta.error ? true : false}
												helperText={meta.touched && meta.error ? meta.error : undefined}
											/>
										)}
									</FastField>
								</SttGrid>
								<SttGrid
									item
									xs={12}
									sm={6}
								>
									<Field name={TEMA}>
										{({ field: { name, value, onBlur }, form: { setFieldValue }, meta }) => (
											<SttAutocomplete
												inputprops={{
													name: name,
													label: strings.tema,
													required: true,
													error: meta.touched && meta.error ? meta.error : undefined
												}}
												getOptionLabel={(option) => option?.descricao || ''}
												getOptionSelected={(option, valueOptionSelected) => option?.id === valueOptionSelected?.id}
												options={temas}
												value={value}
												onBlur={onBlur}
												onChange={(e, item) => {
													setFieldValue(TEMA, item || null);
												}}
											/>
										)}
									</Field>
								</SttGrid>
								<SttGrid
									item
									xs={12}
								>
									<FastField name={URL}>
										{({ field, meta }) => (
											<SttInput
												{...field}
												label={strings.url}
												required={true}
												error={meta.touched && meta.error ? true : false}
												helperText={meta.touched && meta.error ? meta.error : undefined}
											/>
										)}
									</FastField>
								</SttGrid>
								<SttGrid
									item
									xs={12}
								>
									<FastField name={DESCRICAO_MATERIAL}>
										{({ field, meta }) => (
											<SttInput
												required={true}
												multiline
												rows={5}
												{...field}
												label={strings.descricaoMaterial}
												error={meta.touched && meta.error ? true : false}
												helperText={meta.touched && meta.error ? meta.error : undefined}
											/>
										)}
									</FastField>
								</SttGrid>
							</SttGrid>
						</form>
					</SttContainer>
				);
			}}
		</Formik>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
		setOpen: (open) => dispatch(setOpenAction(open)),
		mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo))
	};
};

export default connect(null, mapDispatchToProps)(FormOpcoes);
